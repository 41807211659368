<template>
  <div class="lead-item-theme" @click="handleClick">
    <div class="lead-item-content">
      <div class="lead-item-author-row">
        <div v-if="item.avatar" class="lead-item-avatar" />
        <div class="lead-item-channel">
          {{ item.channel }}
        </div>
      </div>

      <div class="lead-item-date">
        {{ $global.timeAgo($global.dateStringToTimestamp(item.published)) }}
      </div>
    </div>
    <h3 class="lead-item-title">
      {{ item.title }}
    </h3>
    <div class="lead-item-sources">
      <img :src="item.headImg?.[0]" alt="">
    </div>
  </div>
</template>

<script>
import '@/css/module/lead/theme_11.scss';

export default {
  props: {
    item: {
      require: true,
      type: Object,
      default () {
        return {}
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
